import { takeLatest } from 'redux-saga/effects';

import * as types from '../Actions';

import {
    getDashboardDataSaga,
} from './DashboardSaga'

export default function* watchUserAction() {
    yield takeLatest(types.GET_DASHBOARD_DATA, getDashboardDataSaga)
}
