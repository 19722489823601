import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import ProductDetails from './Pages/ProductDetails';

const ProductDetailsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/product" exact component={ProductDetails} />
        </Fragment>
    )
}

export default withRouter(ProductDetailsRoute)
