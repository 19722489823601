import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./ContactUsStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Paper, Typography, Button } from "@mui/material"
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Images } from "../../../../Theme/Images";
import { addContactUsService } from "../../../../Services/dashboardService";
import { number } from "yup/lib/locale";

const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username required").label("Username").max(50).matches(/^[a-zA-Z]+$/, "Invalid username"),
    name: Yup.string().required("Name required").label("Name").max(50).matches(/^[a-zA-Z_ ]*$/, "Invalid name"),
    email: Yup.string().required("Email required").max(50).email("Invalid email address").label("Email"),
    userType: Yup.string().required().label("User Type"),
});

const userTypes = [
    { label: "Internal", value: "Internal" },
    { label: "External", value: "External" },
];

function ContactUs(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("Subject");
    const [department, setDepartment] = useState("Department");
    const [message, setMessage] = useState("");
    const [initialValues, setInitialValues] = useState({
        name: "",
        userName: "",
        email: "",
        status: "Active",
        permissionList: [],
        userType: "External",
    })

    const onLeftIconClick = () => {
    }

    const handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    useEffect(() => {
        setInterval(() => {
            localStorage.setItem('selectedItem', 7)
        }, 500);
    })


    const onSubmit = async () => {
        let req = {
            name: name,
            email: email,
            subject: subject,
            department: department,
            message: message,
        }
        let response = await addContactUsService(req)

        setName("")
        setEmail("")
        setMessage("")

    }


    return (
        <Fragment>
            <Layout
                leftIcon={""}
                headerContentLeft="Services"
                headerContentRight=""
                rightIcon=""
                leftIconLink=""
                itemClicked={(data) => props.history.push(data.path)}
                contactusclick={() => props.history.push('/contactus')}
            >
                <BodyContainer>
                    <div style={{ marginTop: "160px" }}>
                        <div className="cu-heading-div">
                            <h3 className="cu-header-text">Contact Us</h3>
                        </div>
                        <div className="cu-sub-div">
                            <h3 className="cu-sub-text">Molestie egestas aenean morbi faucibus ornare at nec. Volutpat a adipiscing neque, phasellus in in in dignissim sagittis.</h3>
                        </div>

                        <Grid>
                            <Formik
                                initialValues={initialValues}
                                //onSubmit={handleSubmit}
                                validationSchema={validationSchema}
                            >
                                {({ values, setFieldValue, handleChange, handleReset }) => {

                                    return (
                                        <Form>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container style={{ flex: 1, flexDirection: 'row' }}>
                                                        <Grid style={{ width: '558px' }}>
                                                            <input type="text" placeholder="Name" value={name} style={{ marginTop: '32px' }} onChange={(e) => setName(e.target.value)} />
                                                            <input type="text" placeholder="Email" value={email} style={{ marginTop: '24px' }} onChange={(e) => setEmail(e.target.value)} />
                                                            <select className="classic" style={{ marginTop: '24px' }} value={subject} onChange={(e) => setSubject(e.target.value)}>
                                                                <option value="Subject">Subject</option>
                                                            </select>
                                                            <select className="classic" style={{ marginTop: '24px' }} value={department} onChange={(e) => setDepartment(e.target.value)}>
                                                                <option value="Department">Department</option>
                                                            </select>

                                                            <div style={{ marginTop: '24px' }}>
                                                                <div style={{ width: '199px', height: '40px', borderRadius: '4px', paddingTop: "8px", paddingBottom: '8px', display: 'flex', justifyContent: 'center', backgroundColor: '#3B82F6' }} onClick={() => onSubmit()}>
                                                                    <p style={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px', fontWeight: 600, color: '#EFF6FF' }}>Send Message</p>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid style={{ width: '558px', height: '264px', marginLeft: '30px' }}>
                                                            <textarea type="text" placeholder="Message" value={message} style={{ marginTop: '32px' }} onChange={(e) => setMessage(e.target.value)} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                    </div>
                </BodyContainer>
            </Layout>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);