import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./NewsView.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Link, Typography } from "@mui/material"
import { Images } from "../../../../Theme/Images"
import moment from 'moment'

class NewsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            selectedNews: {}
        };
    };
    componentDidMount() {
        let selectedService = JSON.parse(localStorage.getItem('selectedNews'))
        this.setState({
            breadcrumbs: [
                <Link underline="hover" key="1" color="inherit" onClick={() => this.props.history.push('news')} >
                    News
                </Link>,
                <Typography key="3" style={{ fontFamily: "Poppins", fontSize: '14px', fontWeight: 400, color: '#9ca3af' }}>
                    {selectedService.news_name}

                </Typography>,
            ],
            selectedNews: selectedService
        })
    }

    onLeftIconClick() {
    }


    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "128px" }}>
                            <div className="sv-heading-div">
                                <Breadcrumb breadcrumbs={this.state.breadcrumbs} />
                            </div>
                            <div style={{ marginTop: "48px" }}>
                                <Grid style={{ margin: "auto", width: "754px", height: "320px", backgroundImage: `url(${Images.news.img_news_view})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: "4px", alignSelf: "center" }}>
                                </Grid>

                                <div style={{ marginTop: "24px" }}>
                                    <div style={{ width: "754px", height: "40px", margin: "auto" }}>
                                        <h1 className="nv-header-text">{this.state.selectedNews.news_name}</h1>

                                    </div>
                                </div>
                                <div style={{ marginTop: "16px" }}>
                                    <div style={{ alignSelf: 'center', width: "754px", height: "21px", margin: 'auto', textAlign: 'center' }} >
                                        <h1 className="nv-pub-text">Published On: {moment(this.state.selectedNews.published_date).format('DD MMM YYYY')}</h1>

                                    </div>
                                </div>

                                <div style={{ marginTop: "40px", marginBottom: '20px' }}>
                                    {this.state.selectedNews.description ?
                                        this.state.selectedNews.description.map(item => {
                                            return (
                                                <div style={{ marginTop: '20px' }}>
                                                    <div style={{ alignSelf: 'center', width: "754px", margin: 'auto' }} >
                                                        <h1 style={{ fontFamily: 'Poppins', fontWeight: item.weight, fontSize: '20px', lineHeight: '30px', color: item.type == "sub-topic" ? "#1F2937" : "#4B5563" }}>{item.content}</h1>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null}

                                </div>

                            </div>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewsView);