import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./PartnerView.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Product from "../../Products/SubComponents/Product";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import Accordian from "../../../../Components/Accordian/Accordian";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Link, Typography } from "@mui/material"
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import { Images } from "../../../../Theme/Images"
import { IMAGE_URL } from "../../../../Utils/constants"

class PartnerView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            selectedPartner: {}
        };
    };
    componentDidMount() {
        let partner = JSON.parse(localStorage.getItem('selectedPartner'))
        this.setState({
            breadcrumbs: [
                <Link underline="hover" key="1" color="inherit" onClick={() => this.props.history.push('partners')} >
                    Partners
                </Link>,
                // <Link
                <Typography key="3" color="text.primary">
                    {partner.partner_name}
                </Typography>,
            ],
            selectedPartner: partner
        })
    }

    onLeftIconClick() {
    }

    handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    multipleElements() {

        let elements = [];
        for (let i = 1; i <= 30; i++) {
            elements.push(
                <div key={i} style={{ marginRight: i % 3 == 0 ? "0px" : "30px" }}>

                </div>
            )
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 3) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 3).map(item => {
                return <div style={{ display: 'inline-block', justifyContent: "space-between", marginTop: "32px" }}>{item}</div>
            }))
            separateElements.push(oneRow.map(itm => { return <div>{itm}</div> }))
        }
        return separateElements;
    }

    itemClick() {
        this.props.history.push('product-view')
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "128px" }}>
                            <div className="pd-heading-div">
                                <Breadcrumb breadcrumbs={this.state.breadcrumbs} />
                            </div>

                            <Grid container style={{ flex: 1, flexDirection: 'row', marginTop: '39px' }}>
                                <div className="pav-item">
                                    <img src={IMAGE_URL + this.state.selectedPartner.image} />
                                </div>
                                <Grid style={{ paddingLeft: '30px', width: '754px' }} >
                                    <Typography style={{ fontFamily: "Poppins", fontSize: '40px', lineHeight: '40px', fontWeight: 600, color: "#1F2937" }}>{this.state.selectedPartner.partner_name}</Typography>
                                    <div style={{ marginTop: '24px' }}>
                                        <h2 style={{ fontSize: '20px', fontFamily: "Poppins", fontWeight: 400, lineHeight: '30px', color: '#4B5563' }}>{this.state.selectedPartner.descriptiom}</h2>
                                    </div>
                                    <div style={{ marginTop: '48px' }}>
                                        <h2 style={{ fontSize: '20px', fontFamily: "Poppins", fontWeight: 600, lineHeight: '20px', color: '#1F2937' }}>Products</h2>
                                    </div>

                                    <div style={{ marginTop: '20px' }}>
                                        {this.state.selectedPartner.products ?
                                            this.state.selectedPartner.products.map(item => {
                                                return (
                                                    <li style={{ fontSize: '20px', fontFamily: "Poppins", fontWeight: 400, lineHeight: '30px', color: '#4B5563' }}>{item.product}</li>
                                                )
                                            })
                                            : null}
                                    </div>

                                </Grid>
                            </Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}




const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PartnerView);