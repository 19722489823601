import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./AboutUs.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Link, Typography } from "@mui/material"
import { Images } from "../../../../Theme/Images";
import { getAboutUsService } from "../../../../Services/dashboardService"

const BASE_URL = process.env.REACT_APP_DEV_API_URL;
class AboutUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            aboutUs: {}
        };
    };
    componentDidMount() {
        this.getAboutUsData()
    }

    getAboutUsData = async () => {
        let response = await getAboutUsService()
        this.setState({
            aboutUs: response.data && response.data.data ? response.data.data[0] : []
        })
        console.log(response);
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "128px" }}>

                            <div style={{ width: "754px", height: "40px" }}>
                                <h1 className="au-header-text">About Our Company
                                </h1>
                            </div>

                            <div style={{ marginTop: "16px" }}>
                                {this.state.aboutUs && this.state.aboutUs.description ?
                                    this.state.aboutUs.description.map(item => {
                                        return (
                                            <div style={{ alignSelf: 'center', width: "754px" }} >
                                                <h1 className="au-sub-text">{item.content}</h1>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>


                            <div style={{ marginTop: "32px" }}>
                                <Grid style={{ width: "754px", height: "320px", backgroundImage: `url(${this.state.aboutUs.image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: "4px", alignSelf: "center" }}>
                                </Grid>
                            </div>

                            <div style={{ marginTop: "48px" }}>
                                <h1 className="au-sec-text">Chairman’s Message</h1>
                            </div>

                            <div style={{ marginTop: "16px" }}>
                                <div style={{ alignSelf: 'center', width: "754px", height: "192px" }} >
                                    <h1 className="au-sub-text">{this.state.aboutUs.chairman_message}
                                    </h1>

                                </div>
                            </div>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}
const selectedService = JSON.parse(localStorage.getItem('selectedService'))
const breadcrumbss = [
    <Link underline="hover" key="1" color="inherit" href="/" >
        Service
    </Link>,
    // <Link
    //     underline="hover"
    //     key="2"
    //     color="inherit"
    //     href="/services"
    // >
    //     {selectedService.item}
    // </Link>,
    <Typography key="3" color="text.primary">
        {selectedService ? selectedService.item : ""}
    </Typography>,
];



const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);