import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import News from './Pages/News';

const NewsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/news" exact component={News} />
        </Fragment>
    )
}

export default withRouter(NewsRoute)
