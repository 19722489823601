import * as types from '../Actions';

export const initailState = {
    dashboard: {},
    accountSummary: {},
    investments: [],
    investmentAdvisor: {},
    token: null
};

export default function (state = initailState, action) {
    let response = action.response;
    switch (action.type) {
        // Fetch starpoints
        case types.GET_DASHBOARD_DATA_SUCCESS:
            return { ...state, dashboard: response.data.DATA };
        case types.GET_DASHBOARD_DATA_FAIL:
            return { ...state, dashboard: {} };
        case types.GET_ACCOUNT_SUMMARY_SUCCESS:
            return { ...state, accountSummary: response.data };
        case types.GET_ACCOUNT_SUMMARY_FAIL:
            return { ...state, accountSummary: {} };
        case types.GET_INVESTMENT_ADVISOR_SUCCESS:
            return { ...state, investmentAdvisor: response.data.DATA };
        case types.GET_INVESTMENT_ADVISOR_FAIL:
            return { ...state, investmentAdvisor: {} };

        default:
            return state;
    }
}