import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import Dashboard from './Pages/Dashboard';

const DashboardRoute = (props) => {
    return (
        <Fragment>
            <Route path="/" exact component={Dashboard} />
        </Fragment>
    )
}

export default withRouter(DashboardRoute)
