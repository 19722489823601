import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Images } from '../../../../Theme/Images';
import { makeStyles } from '@mui/styles';
import "./Product.scss";
import { IMAGE_URL } from "../../../../Utils/constants"
export default function Product(props) {
    const { title, descrption, onClick, item } = props
    const classes = useStyles();

    const [data, setData] = useState(item ? item : {})

    useEffect(() => {
        setData(item)
    }, [item]);

    const handleClick = () => {
        onClick()
    }

    return (

        <div className="div-gradient" style={{ width: "362px", height: "362px", backgroundImage: `url(${data ? IMAGE_URL + data.image : ""})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', position: 'relative' }}>
            <div className="div-gradient">
                <div style={{ position: 'absolute', bottom: '24px', left: 0, right: 0 }}>
                    <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600, color: "#EFF6FF" }}>{data ? data.product_name : ""}</Typography>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "20px",
        color: "#1F2937",
        lineHeight: "20px"
    },
    text: {
        fontFamily: "Poppins",
        fontWeight: 'normal',
        fontSize: "16px",
        color: "#6B7280",
        lineHeight: "150%"
    },
    readMore: {
        fontFamily: "Poppins",
        fontWeight: '600',
        fontSize: "16px",
        color: "#2563EB",
        lineHeight: "16px"
    }
}));
