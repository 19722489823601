import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import ProductView from './Pages/ProductView';

const ProductViewRoute = (props) => {
    return (
        <Fragment>
            <Route path="/product-view" exact component={ProductView} />
        </Fragment>
    )
}

export default withRouter(ProductViewRoute)
