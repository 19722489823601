import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import AboutUs from './Pages/AboutUs';

const AboutUsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/aboutus" exact component={AboutUs} />
        </Fragment>
    )
}

export default withRouter(AboutUsRoute)
