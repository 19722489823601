

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const GET_ACCOUNT_SUMMARY = "GET_ACCOUNT_SUMMARY";
export const GET_ACCOUNT_SUMMARY_SUCCESS = "GET_ACCOUNT_SUMMARY_SUCCESS";
export const GET_ACCOUNT_SUMMARY_FAIL = "GET_ACCOUNT_SUMMARY_FAIL";

export const GET_INVESTMENT_ADVISOR = "GET_INVESTMENT_ADVISOR";
export const GET_INVESTMENT_ADVISOR_SUCCESS = "GET_INVESTMENT_ADVISOR_SUCCESS";
export const GET_INVESTMENT_ADVISOR_FAIL = "GET_INVESTMENT_ADVISOR_FAIL";
