import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'
import PropTypes from "prop-types";
import { Images } from '../../Theme/Images'
import { Divider, Grid } from '@mui/material';
import { borderColor } from '@mui/system';


const data = [
    {
        id: 0,
        title: 'Home',
        path: '/'
    },
    {
        id: 1,
        title: 'Products',
        path: '/products'
    },
    {
        id: 2,
        title: 'Partners',
        path: '/partners'
    },
    {
        id: 3,
        title: 'Services',
        path: '/services'
    },
    {
        id: 4,
        title: 'Careers',
        path: '/careers'
    },
    {
        id: 5,
        title: 'News',
        path: '/news'
    },
    {
        id: 6,
        title: 'About Us',
        path: '/aboutus'
    },
]
export default function Header(props) {

    const [itemSelected, setItemSelected] = React.useState(0)
    const [showHeader, setValue] = React.useState(true);
    const [selectedItem, setSelectedItem] = React.useState(0);
    const [divWidth, setDivWidth] = React.useState('48px')
    const [borderWidth, setBorderWidth] = React.useState(itemSelected == 0 ? '72px' : '80px')
    const { handleLeftIconClick, handleRightIconClick, handleBuyPressed, handleSellPressed, itemClicked, contactusclick } = props;

    React.useEffect(() => {
        setItemSelected(localStorage.getItem('selectedItem'))
    }, [])

    const onClickHeaderItem = (item, index) => {
        localStorage.setItem('selectedItem', item.id)
        setSelectedItem(item.id)
        itemClicked(item)
    }

    const contactUsClicked = () => {
        contactusclick()
    }


    if (window.location.pathname === "/" && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('Home')
    } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('NotHome')
    }
    return (
        <div>
            <header className={itemSelected == 0 ? styles.home_container : styles.header_container}>
                <div style={{ width: '64px', height: '64px', marginLeft: '147px', marginRight: '132px' }}>
                    <img src={itemSelected == 0 ? Images.logo.logo_white : Images.logo.logo_blue} alt="icon" />
                </div>
                {data.map((item, index) => {
                    return (
                        <div key={index} style={{ height: '24px', marginRight: itemSelected == 0 ? '40px' : '25px', width: borderWidth, textAlign: 'center' }} onClick={() => onClickHeaderItem(item, index)}>
                            {itemSelected == 0 ?
                                <h2 style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: itemSelected == item.id ? '#FB923C' : '#BFDBFE' }}>{item.title}</h2> :
                                <h2 style={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', color: itemSelected == item.id ? '#1D4ED8' : '#1F2937' }}>{item.title}</h2>}
                            {itemSelected == 0 ?
                                <div style={{ width: borderWidth, borderStyle: 'solid', borderTopWidth: '0px', borderBottomWidth: itemSelected == item.id ? '1.5px' : "0px", borderLeftWidth: '0px', borderRightWidth: '0px', borderColor: itemSelected == item.id ? '#FB923C' : '#BFDBFE' }}></div> :
                                <div style={{ width: borderWidth, borderStyle: 'solid', borderTopWidth: '0px', borderBottomWidth: itemSelected == item.id ? '1.5px' : "0px", borderLeftWidth: '0px', borderRightWidth: '0px', borderColor: itemSelected == item.id ? '#1D4ED8' : '#1F2937' }}></div>}

                        </div>
                    )
                })}
                <select className="classic" style={{ marginTop: '8px', width: '85px', height: '40px', backgroundColor: 'rgba(255, 255, 255, 0)' }}>
                    <option value="Position">EN</option>
                </select>
                {itemSelected == 0 ? null :
                    <div style={{ width: '136px', height: '40px', borderStyle: 'solid', borderRadius: '4px', paddingTop: "8px", paddingBottom: '8px', borderColor: '#2563EB', borderWidth: '1px', display: 'flex', justifyContent: 'center', marginLeft: '32px', marginTop: '5px' }} onClick={() => contactUsClicked()}>
                        <p style={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px', fontWeight: 400, color: '#2563EB' }}>Contact Us</p>
                    </div>}

            </header>
        </div>
    )
}

Header.propTypes = {
    handleOnClick: PropTypes.func,
    handleRightIconClick: PropTypes.func,
    handleLeftIconClick: PropTypes.func,
    itemClicked: PropTypes.func,
    contactusclick: PropTypes.func
};

Header.defaultProps = {
    rightButton: false,
    sellStockEnable: "",
    handleLeftIconClick: () => { },
    itemClicked: () => { },
    contactusclick: () => { }
};