import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./ServiceViewStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Link, Typography } from "@mui/material"
import { Images } from "../../../../Theme/Images"

class ServiceView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            selectedService: {}
        };
    };
    componentDidMount() {
        let data = JSON.parse(localStorage.getItem('selectedService'))
        this.setState({
            selectedService: data,
            breadcrumbs: [
                <Link underline="hover" key="1" color="inherit" onClick={() => this.props.history.push('services')} >
                    Service
                </Link>,
                <Typography key="3" style={{ fontFamily: "Poppins", fontSize: '14px', fontWeight: 400, color: '#9ca3af' }}>
                    {data.service_name}
                </Typography>,
            ]
        })
    }

    onLeftIconClick() {
    }

    multipleElements() {

        let elements = [];
        for (let i = 0; i < 30; i++) {
            elements.push(
                <div key={i} style={{ marginRight: "30px" }}>
                    <CardComponent />
                </div>
            )
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 3) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 3).map(item => {
                return <div style={{ display: 'inline-block', justifyContent: "space-between", marginTop: "32px" }}>{item}</div>
            }))
            separateElements.push(oneRow.map(itm => { return <div>{itm}</div> }))
        }
        return separateElements;
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "128px" }}>
                            <div className="sv-heading-div">
                                <Breadcrumb breadcrumbs={this.state.breadcrumbs} />
                            </div>
                            <div style={{ marginTop: "48px" }}>
                                <Grid style={{ margin: "auto", width: "754px", height: "320px", backgroundImage: `url(${Images.services.img_enargy_service})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: "4px", alignSelf: "center" }}>
                                </Grid>

                                <div style={{ marginTop: "24px" }}>
                                    <div style={{ width: "754px", height: "40px", margin: "auto" }}>
                                        <h1 className="sv-header-text">{this.state.selectedService.service_name}</h1>
                                    </div>
                                </div>
                                <div style={{ marginTop: "40px" }}>
                                    <div style={{ alignSelf: 'center', width: "754px", height: "90px", margin: 'auto' }} >
                                        <h1 className="sv-sub-text">{this.state.selectedService.descriptiom}</h1>
                                    </div>
                                </div>

                                <div style={{ marginTop: "40px" }}>
                                    <div style={{ alignSelf: 'center', width: "754px", height: "40px", margin: 'auto' }} >
                                        <h1 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "20px", lineHeight: "20px" }}>Key Features for our Energy Management Systems are as mentioned below:</h1>
                                    </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                    <div style={{ alignSelf: 'center', width: "754px", margin: 'auto' }} >
                                        {this.state.selectedService.key_features ?
                                            this.state.selectedService.key_features.map(item => {
                                                return (
                                                    <li style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "20px", lineHeight: "30px", color: "#4B5563" }}>{item.feature}</li>
                                                )
                                            })

                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        dashboardData: state.DashboardReducer.dashboard,
        accountSummary: state.DashboardReducer.accountSummary,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardData: (callback) => dispatch(getDashboardData(callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ServiceView);