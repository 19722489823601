import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Images } from '../../Theme/Images';
import { makeStyles } from '@mui/styles';

export default function CardComponent(props) {
    const { title, descrption, onClick, image } = props
    const classes = useStyles();

    const handleClick = () => {
        onClick()
    }

    return (
        <Grid style={{ borderRadius: '4px', width: '362px', height: '406px' }}>
            <CardMedia
                component="img"
                alt="green iguana"
                height="250px"
                image={image ? image : ""}
            />
            <div style={{ marginTop: '24px' }}>
                <Typography className={classes.header} gutterBottom variant="h5" component="div">
                    {title ? title : ""}
                </Typography>
            </div>
            <div style={{ marginTop: '12px', maxHeight: '72px', overflow: 'hidden' }}>
                <Typography className={classes.text} style={{ wordWrap: 'break-word' }} >
                    {descrption ? descrption : ""}

                </Typography>
            </div>
            <div style={{ marginTop: '12px' }}>
                <Button className={classes.readMore} size="small" onClick={() => handleClick()}>Read More</Button>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "20px",
        color: "#1F2937",
        lineHeight: "20px",
        padding: 0
    },
    text: {
        fontFamily: "Poppins",
        fontWeight: 'normal',
        fontSize: "16px",
        color: "#6B7280",
        lineHeight: "150%",
    },
    readMore: {
        fontFamily: "Poppins",
        fontWeight: '600',
        fontSize: "16px",
        color: "#2563EB",
        lineHeight: "16px",
        padding: 0
    },
    root: {
        width: '100%',
        "& .css-46bh2p-MuiCardContent-root": {
            padding: "0px"
        },
    },
}));
