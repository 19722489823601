import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Accordian(props) {
    const { title, onClickItem, data } = props

    const onItemClick = (item) => {
        onClickItem(item)
    }

    return (
        <div>
            <Accordion elevation={0} style={{ width: '344px', paddingLeft: 0 }}>
                <AccordionSummary
                    style={{ padding: 0, height: '30px' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: '20px', lineHeight: '30px', color: '#4B5563' }}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingLeft: 24, paddingRight: 0, paddingTop: 0 }}>
                    {data && data[0] ?
                        data.map(item => {
                            return (
                                <Grid onClick={() => onItemClick(item)}>
                                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: '20px', lineHeight: '30px', color: '#374151', marginTop: data[0] ? '0px' : '8px' }}>
                                        {item.name}
                                    </Typography>
                                </Grid>
                            )
                        })
                        : null}
                    <Divider style={{ marginTop: '12px' }} />
                </AccordionDetails>
            </Accordion>
        </div >
    );
}
