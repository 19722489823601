import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import ContactUs from './Pages/ContactUs';

const ContactUsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/contactus" exact component={ContactUs} />
        </Fragment>
    )
}

export default withRouter(ContactUsRoute)
