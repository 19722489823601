import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./NewsStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import { Grid, Container, Stack } from "@mui/material"
import { Images } from "../../../../Theme/Images"
import { getNewsService } from "../../../../Services/dashboardService"
import { IMAGE_URL } from "../../../../Utils/constants"

let news = [
    {
        image: Images.news.img_news_1,
        news_name: "Lorem Ipsum Dolor Amente",
        description: "Consequat felis faucibus diam quam ante adipiscing diam. Cum non ac at cras est tristique."
    },
    {
        image: Images.news.img_news_2,
        news_name: "Lorem Ipsum Dolor Amente",
        description: "Amet at tortor tortor nunc. Pulvinar risus neque, nulla leo in egestas."
    },
    {
        image: Images.news.img_news_3,
        news_name: "Lorem Ipsum Dolor Amente",
        description: "Suspendisse vivamus euismod leo natoque rhoncus, volutpat dolor. Ut vel consectetur orci sed imperdiet id."
    },
    {
        image: Images.news.img_news_4,
        news_name: "Lorem Ipsum Dolor Amente",
        description: "Non dolor purus fermentum porttitor eros, urna, sed lacus enim. Gravida sit nulla vel lobortis lacus."
    },
    {
        image: Images.news.img_news_5,
        news_name: "Lorem Ipsum Dolor Amente",
        description: "Id nunc eget sit sed. Varius donec nisl tellus nibh sit molestie porta etiam vestibulum."
    }

]

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            news: []
        };
    };



    componentDidMount() {
        this.getNews()
    }

    getNews = async () => {
        let response = await getNewsService()
        console.log(response);
        this.setState({
            news: response.data.data
        }, () => {
            console.log(this.state.news);
        })

    }

    handleViewClick = (data) => {
        localStorage.setItem("selectedNews", JSON.stringify(data))
        this.props.history.push('news-view')
    }

    multipleElements() {

        let elements = [];
        if (this.state.news.length > 0) {
            for (let i = 0; i < this.state.news.length; i++) {
                elements.push(
                    <div key={i} style={{ marginRight: i % 3 == 2 ? "0px" : "30px" }}>
                        <CardComponent
                            image={IMAGE_URL + this.state.news[i].image}
                            title={this.state.news[i].news_name}
                            descrption={this.state.news[i].description[0].content}
                            onClick={() => this.handleViewClick(this.state.news[i])}
                        />
                    </div>
                )
            }
        } else {
            console.log("asasas");
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 3) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 3).map(item => {
                return <Grid style={{ display: 'inline-block' }}>{item}</Grid>
            }))
            separateElements.push(oneRow.map(itm => { return <div style={{ marginTop: '32px', display: 'flex' }}>{itm}</div> }))
        }
        return separateElements;
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "160px" }}>
                            <div className="n-heading-div">
                                <h3 className="n-header-text">Top News About The Industry</h3>
                            </div>
                            <div className="n-sub-div">
                                <h3 className="n-sub-text">Eget sit molestie sed placerat elit, sagittis amet, placerat. Adipiscing praesent in aliquam cursus quis morbi ultrices morbi.</h3>
                            </div>

                            <Grid spacing={"30px"}>{this.separateElement()}</Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(News);