import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import Products from './Pages/Products';

const ProductsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/products" exact component={Products} />
        </Fragment>
    )
}

export default withRouter(ProductsRoute)
