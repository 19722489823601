import axios from "axios";
import dotenv from "dotenv";

dotenv.config();
axios.defaults.timeout = 20000;

const BASE_URL = process.env.REACT_APP_DEV_API_URL;


export const getDashboardDataService = async (req) => {
  // const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/dashboard/${req.broker}`;

};

export const getProductsService = async () => {
  // const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/dashboard/${req.broker}`;
  const REGISTER_API_ENDPOINT = `${BASE_URL}/product`;
  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};

export const getPartnersService = async () => {
  // const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/dashboard/${req.broker}`;
  const REGISTER_API_ENDPOINT = `${BASE_URL}/partners`;
  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};

export const getServicesService = async () => {
  const REGISTER_API_ENDPOINT = `${BASE_URL}/service`;
  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};


export const getAboutUsService = async () => {
  const REGISTER_API_ENDPOINT = `${BASE_URL}/aboutus`;
  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};


export const getNewsService = async () => {
  const REGISTER_API_ENDPOINT = `${BASE_URL}/news`;
  let res = await axios
    .get(REGISTER_API_ENDPOINT)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};


export const addCareersService = async (req) => {
  const REGISTER_API_ENDPOINT = `https://electricindia.herokuapp.com/api/v1/submission`;
  let res = await axios
    .post(REGISTER_API_ENDPOINT, req)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};

export const addContactUsService = async (req) => {
  let res = await axios
    .post("https://electricindia.herokuapp.com/api/v1/contactus", req)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return res;
};