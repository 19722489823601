import React, { Fragment } from 'react';
import { Images } from "../../Theme/Images"
import "./Footer.scss"

function Footer(props) {
    const appStyle = {
        width: '100%',
        height: '56px',
        bottom: '0',
        zIndex: '1',
        //margin:'1%',
        left: 0,
        paddingLeft: '147px',
        // backgroundColor:'#f9f9f9',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row'

    }
    return (
        <Fragment>
            <div style={appStyle}>
                <h5 style={{ width: '399px', paddingTop: '18px', height: '21px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: '21px', fontWeight: 400, color: '#9CA3AF' }}>© 2021 Powertech Trading & Cont Will. All Rights Reserved.</h5>

                <img style={{ height: '24px', width: '24px', marginTop: '16px', marginLeft: '627px' }} src={Images.icon.ic_f3} />
                <img style={{ height: '24px', width: '24px', marginTop: '16px', marginLeft: '24px' }} src={Images.icon.ic_f1} />
                <img style={{ height: '24px', width: '24px', marginTop: '16px', marginLeft: '24px' }} src={Images.icon.ic_f2} />
            </div>

        </Fragment>

    );
}
export default Footer;
