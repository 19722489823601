import { put, call, select } from 'redux-saga/effects';

import * as types from "../Actions";
import {
    getDashboardDataService,
} from '../Services/dashboardService';
import { accessToken, selectedBroker } from "../Selectors/commonSelector"

export function* getDashboardDataSaga(action) {
    const access_Token = yield select(accessToken);
    const selected_Broker = yield select(selectedBroker)
    let req = {
        accToken: access_Token,
        broker: selected_Broker
    }
    try {
        // yield put({ type: types.FULL_SCREEN_LOADER, payload: { visibility: true, text: '' } });
        let response = yield call(getDashboardDataService, req)
        if (response.data.STATUS === "ACCEPTED") {
            yield put({ type: types.GET_DASHBOARD_DATA_SUCCESS, response })
            //yield put({ type: types.FULL_SCREEN_LOADER, payload: { visibility: false, text: '' } });


        } else {
            yield put({ type: types.GET_DASHBOARD_DATA_FAIL, response })
            // yield put({ type: types.FULL_SCREEN_LOADER, payload: { visibility: false, text: '' } });
        }
    } catch (error) {
        yield put({ type: types.GET_DASHBOARD_DATA_FAIL, response: error })
        //yield put({ type: types.FULL_SCREEN_LOADER, payload: { visibility: false, text: '' } });
    }
    action.callback()
}