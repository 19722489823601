import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./DashboardStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import {
  getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Images } from "../../../../Theme/Images"
import Carousel from "react-multi-carousel";
import Footer from "../../../../Components/FooterArea/Footer";
import "react-multi-carousel/lib/styles.css";
import { Translator, Translate } from 'react-auto-translate';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  };



  componentDidMount() {
    localStorage.setItem('selectedItem', 0)
  }

  onLeftIconClick() {
  }

  render() {
    return (
      <Translator
        from='en'
        to='es'
        googleApiKey='API_KEY'
      >
        <Fragment>
          <Layout
            leftIcon={""}
            headerContentLeft="Dashboard"
            headerContentRight=""
            rightIcon=""
            leftIconLink=""
            itemClicked={(data) => this.props.history.push(data.path)}
          >
            <div style={{ width: "100%", backgroundImage: `url(${Images.dashboard.img_dashboard})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <div className="backgrount-div">
                <div style={{ paddingTop: '305px' }}>
                  <div style={{ width: '1146px', height: '158px', margin: 'auto' }}>
                    <h1 style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: "72px", lineHeight: '79px', textAlign: 'center', color: "#EFF6FF" }}>We Provide Best In Class Electrical Products</h1>
                  </div>
                  <div style={{ width: '1146px', height: '72px', margin: 'auto' }}>
                    <h1 style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: "24px", lineHeight: '36px', textAlign: 'center', color: "#93C5FD" }}>Egestas viverra ac, nam netus sapien sed in.Ac lacinia diam orci, ullamcorper.Ante auctor phasellus a orci, dictum enim, quis faucibus laoreet.</h1>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <div style={{ width: '1146px', height: '72px', margin: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <div style={{ width: '196px', height: '40px', borderStyle: 'solid', borderRadius: '4px', paddingTop: "5px", paddingBottom: '8px', borderColor: '#EFF6FF', marginRight: '32px', display: 'flex', justifyContent: 'center' }} onClick={() => this.props.history.push('products')}>
                        <p style={{ width: '116px', height: '24px', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '150%', fontWeight: 600, color: '#EFF6FF' }}>View Products</p>
                      </div>

                      <div style={{ width: '196px', height: '40px', borderStyle: 'solid', borderRadius: '4px', paddingTop: "5px", paddingBottom: '8px', borderColor: '#EFF6FF', display: 'flex', justifyContent: 'center' }} onClick={() => this.props.history.push('contactus')}>
                        <p style={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '150%', fontWeight: 600, color: '#EFF6FF' }}>Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <h2 style={{ textAlign: 'center', marginTop: '173px', fontFamily: 'Poppins', fontWeight: 600, fontSize: "20px", lineHeight: '20px', color: 'rgba(255, 255, 255, 0.7)' }}>Our Partners</h2>

                  <div style={{ height: '160px', margin: 'auto', width: "1060px" }}>
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile", 'desktop']}
                      deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="item"
                    >
                      <div style={{ width: '160px', height: '160px', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={Images.home_partners.img_apc} />
                      </div>
                      <div style={{ width: '160px', height: '160px', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={Images.home_partners.img_hager} />
                      </div>
                      <div style={{ width: '160px', height: '160px', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={Images.home_partners.img_schneider} />
                      </div>
                      <div style={{ width: '160px', height: '160px', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={Images.home_partners.img_siemens} />
                      </div>
                    </Carousel>
                  </div>
                </div>

              </div>
            </div>
          </Layout>
          <Footer />
        </Fragment>
      </Translator>
    );
  }
}



const mapStateToProps = (state) => {
  return {

  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);