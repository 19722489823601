import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import PartnerView from './Pages/PartnerView';

const PartnerViewRoute = (props) => {
    return (
        <Fragment>
            <Route path="/partner-view" exact component={PartnerView} />
        </Fragment>
    )
}

export default withRouter(PartnerViewRoute)
