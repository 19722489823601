import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import NewsView from './Pages/NewsView';

const NewsViewRoute = (props) => {
    return (
        <Fragment>
            <Route path="/news-view" exact component={NewsView} />
        </Fragment>
    )
}

export default withRouter(NewsViewRoute)
