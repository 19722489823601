import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./ProductsStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Product from "../SubComponents/Product";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { Grid, Container, Stack } from "@mui/material"
import { Images } from "../../../../Theme/Images";
import { getProductsService } from "../../../../Services/dashboardService";



const products = [
    {
        image: Images.products.img_product_1,
        titile: "Switchgear"
    },
    {
        image: Images.products.img_product_2,
        titile: "Power Factor Correction & Power Quality Solutions"
    },
    {
        image: Images.products.img_product_3,
        titile: "Measurement"
    },
    {
        image: Images.products.img_product_4,
        titile: "Controlgear & Panel Accessories"
    },
]


class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: []
        };
    };
    componentDidMount() {
        this.getProducts()
    }

    getProducts = async () => {
        let response = await getProductsService()
        this.setState({
            products: response.data && response.data.data ? response.data.data : []
        }, () => {
            console.log("asasas", this.state.products);
        })
    }

    onLeftIconClick() {
    }

    handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    multipleElements() {

        let elements = [];
        for (let i = 0; i < this.state.products.length; i++) {
            elements.push(
                <div key={i} style={{ marginRight: i % 3 == 2 ? "0px" : "30px" }} onClick={() => {
                    localStorage.setItem('selectedProduct', JSON.stringify(this.state.products[i]))
                    this.props.history.push('product')
                }}>
                    <Product item={this.state.products[i]} />
                </div>
            )
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 3) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 3).map(item => {
                return <div style={{ display: 'inline-block', justifyContent: "space-between", marginTop: "32px" }}>{item}</div>
            }))
            separateElements.push(oneRow.map(itm => { return <div>{itm}</div> }))
        }
        return separateElements;
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "160px" }}>
                            <div className="heading-div">
                                <h3 className="header-text">High Quality Products</h3>
                            </div>
                            <div className="sub-div">
                                <h3 className="sub-text">Convallis lacus cursus felis, tempor aliquam est pellentesque lobortis. Vel interdum mauris ut blandit lectus.</h3>
                            </div>

                            <Grid>{this.separateElement()}</Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}



const data = [
    {
        "brokerName": "ASI",
        "brokerCode": "ASI"
    },
    {
        "brokerName": "ABC",
        "brokerCode": "ABC"
    },
]


const mapStateToProps = (state) => {
    return {
        dashboardData: state.DashboardReducer.dashboard,
        accountSummary: state.DashboardReducer.accountSummary,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardData: (callback) => dispatch(getDashboardData(callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Products);