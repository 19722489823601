import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import ServiceView from './Pages/ServiceView';

const ServiceViewRoute = (props) => {
    return (
        <Fragment>
            <Route path="/service-view" exact component={ServiceView} />
        </Fragment>
    )
}

export default withRouter(ServiceViewRoute)
