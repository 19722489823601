import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./ServicesStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack } from "@mui/material"
import { Images } from "../../../../Theme/Images"
import { getServicesService } from "../../../../Services/dashboardService"
import { IMAGE_URL } from "../../../../Utils/constants";

let services = [
    {
        image: Images.services.img_service_1,
        service_name: "Energy Management System",
        descriptiom: "We Implement Energy Management Systems in New or Existing (Retrofitting) installation as per EN50160 standards and Local Authorities such as Estidama."
    },
    {
        image: Images.services.img_service_2,
        service_name: "Harmonic/Power Quality Analysis",
        descriptiom: "We offer a broad range of services in power quality solutions such as: Site surveys, on-site power quality data logging and harmonic analysis."
    },
    {
        image: Images.services.img_service_3,
        service_name: "Building Automation",
        descriptiom: "Design, supply, installation, programming and networking of electronic devices designed to monitor and control the mechanical, security, lighting,"
    },
    {
        image: Images.services.img_service_4,
        service_name: "Capacitor Bank Services",
        descriptiom: "We have expertise in providing  power factor correction solutions across diversified sectors ranging from villas, high rise towers, malls, schools, airports,"
    },
    {
        image: Images.services.img_service_5,
        service_name: "Car Parking Solutions",
        descriptiom: "We implement Parking guidance systems in shopping malls & commercial buildings to save time and easy navigation. Each parking space is equipped."
    },
    {
        image: Images.services.img_service_6,
        service_name: "Project References",
        descriptiom: "Presidential Palace, Abu Dhabi; Port of Fujairah, Fujairah; Midas Cables, Bahrain; S&T Marbles, Oman; K-Flex Gulf Manufacturing, Dubai; DSOA Headquarter."
    }

]

class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: []
        };
    };



    componentDidMount() {
        this.getServices()
    }

    getServices = async () => {
        let response = await getServicesService();
        console.log(response);
        this.setState({
            services: response.data && response.data.data ? response.data.data : []
        })

    }

    handleViewClick = (data) => {
        localStorage.setItem("selectedService", JSON.stringify(data))
        this.props.history.push('service-view')
    }

    multipleElements() {

        let elements = [];
        for (let i = 0; i < this.state.services.length; i++) {
            elements.push(
                <div key={i} style={{ marginRight: i % 3 == 2 ? "0px" : "30px" }}>
                    <CardComponent
                        image={IMAGE_URL + this.state.services[i].image}
                        title={this.state.services[i].service_name}
                        descrption={this.state.services[i].descriptiom}
                        onClick={() => this.handleViewClick(this.state.services[i])}
                    />
                </div>
            )
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 3) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 3).map(item => {
                return <Grid style={{ display: 'inline-block', justifyContent: "space-between" }}>{item}</Grid>
            }))
            separateElements.push(oneRow.map(itm => { return <div style={{ marginTop: '32px', display: 'flex' }}>{itm}</div> }))
        }
        return separateElements;
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    handleLeftIconClick={() => this.onLeftIconClick()}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "160px" }}>
                            <div className="heading-div">
                                <h3 className="header-text">Top News About The Industry</h3>
                            </div>
                            <div className="sub-div">
                                <h3 className="sub-text">Eget sit molestie sed placerat elit, sagittis amet, placerat. Adipiscing praesent in aliquam cursus quis morbi ultrices morbi.</h3>
                            </div>

                            <Grid spacing={"30px"}>{this.separateElement()}</Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}

const data = [
    {
        "brokerName": "ASI",
        "brokerCode": "ASI"
    },
    {
        "brokerName": "ABC",
        "brokerCode": "ABC"
    },
]


const mapStateToProps = (state) => {
    return {
        dashboardData: state.DashboardReducer.dashboard,
        accountSummary: state.DashboardReducer.accountSummary,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardData: (callback) => dispatch(getDashboardData(callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Services);