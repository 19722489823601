import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./Partners.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack } from "@mui/material"
import { Images } from "../../../../Theme/Images"
import { borderColor } from "@mui/system";
import { getPartnersService } from "../../../../Services/dashboardService";
import { IMAGE_URL } from "../../../../Utils/constants"


const partners = [
    {
        image: Images.partners.img_partner_1
    },
    {
        image: Images.partners.img_partner_2
    },
    {
        image: Images.partners.img_partner_3
    },
    {
        image: Images.partners.img_partner_4
    },
    {
        image: Images.partners.img_partner_5
    },
    {
        image: Images.partners.img_partner_6
    },
    {
        image: Images.partners.img_partner_7
    },
    {
        image: Images.partners.img_partner_8
    },
    {
        image: Images.partners.img_partner_9
    },
    {
        image: Images.partners.img_partner_10
    },
    {
        image: Images.partners.img_partner_11
    },
    {
        image: Images.partners.img_partner_12
    },
    {
        image: Images.partners.img_partner_13
    },
    {
        image: Images.partners.img_partner_14
    },
    {
        image: Images.partners.img_partner_15
    },
    {
        image: Images.partners.img_partner_16
    },
    {
        image: Images.partners.img_partner_17
    },
    {
        image: Images.partners.img_partner_18
    },
    {
        image: Images.partners.img_partner_19
    }]

class Partners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mouseOver: false,
            partners: []
        };
    };




    componentDidMount() {
        this.getPartners();
    }

    getPartners = async () => {
        let response = await getPartnersService()
        console.log(response);
        this.setState({
            partners: response.data && response.data.data ? response.data.data : []
        })

    }

    handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    multipleElements() {

        let elements = [];
        for (let i = 0; i < this.state.partners.length; i++) {
            elements.push(
                <div key={i} style={{ marginRight: i % 5 == 4 ? "0px" : "80px" }}  >
                    <div className="p-item" onClick={() => {
                        localStorage.setItem('selectedPartner', JSON.stringify(this.state.partners[i]))
                        this.props.history.push('partner-view')
                    }}>
                        <img src={this.state.partners ? IMAGE_URL + this.state.partners[i].image : ""} />
                    </div>
                </div>
            )
        }
        return elements;
    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        for (var i = 0; i < multiElements.length; i += 5) {
            var oneRow = [];
            oneRow.push(multiElements.slice(i, i + 5).map(item => {
                return <Grid style={{ display: 'inline-block', justifyContent: "space-between", marginTop: "32px" }}>{item}</Grid>
            }))
            separateElements.push(oneRow.map(itm => { return <div>{itm}</div> }))
        }
        return separateElements;
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "160px" }}>
                            <div className="p-heading-div">
                                <h3 className="p-header-text">Our Great Partners</h3>
                            </div>
                            <div className="p-sub-div">
                                <h3 className="p-sub-text">Justo nunc porttitor cursus molestie ipsum pulvinar lorem quam arcu. Faucibus dolor scelerisque vulputate ullamcorper eleifend enim massa.</h3>
                            </div>

                            <Grid spacing={"83px"}>{this.separateElement()}</Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Partners);