import React from "react";
import {
    HashRouter as Router,
    Switch
} from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";
import DashboardRoute from "../Features/Modules/Dashboard/Dashboard.Route";
import ServicesRoute from "../Features/Modules/Services/Services.Route";
import ServiceViewRoute from "../Features/Modules/ServiceView/ServiceView.Route";
import ProductsRoute from "../Features/Modules/Products/Products.Route";
import ContactUsRoute from "../Features/Modules/ContactUs/ContactUs.Route";
import ProductDetailsRoute from "../Features/Modules/Product/Product.Route";
import ProductViewRoute from "../Features/Modules/ProductView/ProductView.Route";
import NewsRoute from "../Features/Modules/News/News.Route";
import NewsViewRoute from "../Features/Modules/NewsView/NewsView.Route";
import AboutUsRoute from "../Features/Modules/AboutUs/AboutUs.Route";
import PartnersRoute from "../Features/Modules/Partners/Partners.Route";
import PartnerViewRoute from "../Features/Modules/PartnerView/PartnerView.Route";
import CareersRoute from "../Features/Modules/Careers/Careers.Route";

const Routes = () => {
    return (
        <Router>
            <Switch>
                <ScrollToTop>
                    <React.Fragment>
                        <DashboardRoute />
                        <ServicesRoute />
                        <ServiceViewRoute />
                        <ProductsRoute />
                        <ContactUsRoute />
                        <ProductDetailsRoute />
                        <ProductViewRoute />
                        <NewsRoute />
                        <NewsViewRoute />
                        <AboutUsRoute />
                        <PartnersRoute />
                        <PartnerViewRoute />
                        <CareersRoute />
                    </React.Fragment>
                </ScrollToTop>
            </Switch>
        </Router>
    );
}

export default Routes;
