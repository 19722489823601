import React, { Fragment } from 'react';
import Header from '../../Components/Header/Header';
import Footer from "../../Components/FooterArea/Footer";
//import SearchBar from "../../Components/SearchBar/SearchBar";

const Layout = (props) => {
    const { showHeader } = props;
    return (
        <Fragment>
            {showHeader ? <Header {...props} /> : null}
            {props.children}
        </Fragment>

    )
}

export default Layout;

Layout.defaultProps = {
    showHeader: true
};
