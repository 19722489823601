import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import Careers from './Pages/Careers';

const CareersRoute = (props) => {
    return (
        <Fragment>
            <Route path="/careers" exact component={Careers} />
        </Fragment>
    )
}

export default withRouter(CareersRoute)
