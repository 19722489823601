import React, { Fragment } from 'react';
import { connect } from "react-redux";
import CircularLoading from "../../Features/Common/CirculerLoading";
import { Grid, Container } from "@mui/material"
import Footer from '../FooterArea/Footer';
// import ErrorPopUp from "../../Components/Alert/ErrorPopUp";

//import PullToRefresh from 'rmc-pull-to-refresh';

// import PullToRefresh from 'react-pull-to-refresh';

// import {PullToRefresh} from 'react-js-pull-to-refresh';

// import { ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";



function BodyContainer(props) {
    const appStyle = {
        flex: 1,
        position: 'relative',
        paddingBottom: '120px',
        overflow: 'hidden',
        minHeight: '1024px'
    }

    const loaderStyles = {
        textAlign: 'center',
        paddingTop: "70%"
        // width: '60px',
        // height: '100px',
        // position: 'absolute',
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // margin: 'auto',
        // overflow: 'auto',
    }

    return (
        <Fragment>
            {props.loader
                ?
                <div style={loaderStyles} data-testid="loader">
                    <CircularLoading />
                </div>
                :
                <Container style={appStyle} className="scroll-behavior: smooth">
                    {/* <ErrorPopUp /> */}
                    {props.children}
                </Container>
            }
            <Footer />

        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        dashboardData: state.DashboardReducer.dashboard,
    };
};

export default connect(mapStateToProps)(BodyContainer);
