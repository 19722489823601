import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./ProductDetailsStyles.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import Product from "../../Products/SubComponents/Product";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import Accordian from "../../../../Components/Accordian/Accordian";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Link, Typography } from "@mui/material"
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import { Images } from "../../../../Theme/Images"

class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
            selectedProduct: {}
        };
    };



    componentDidMount() {
        let selectedService = JSON.parse(localStorage.getItem('selectedProduct'))

        this.setState({
            breadcrumbs: [
                <Link underline="hover" key="1" color="inherit" onClick={() => this.props.history.push('products')} >
                    Products
                </Link>,
                <Typography key="3" style={{ fontFamily: "Poppins", fontSize: '14px', fontWeight: 400, color: '#9ca3af' }}>
                    {selectedService.product_name}
                </Typography>,
            ],
            selectedProduct: selectedService
        })
    }

    onLeftIconClick() {
    }

    handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    multipleElements() {
        let product_catlog = this.state.selectedProduct.product_catelog
        let elements = [];
        if (product_catlog && product_catlog.length > 0) {
            for (let i = 0; i < product_catlog.length; i++) {
                elements.push(
                    <div key={i} style={{ marginLeft: i % 2 == 1 ? '36px' : 0 }}>
                        <Accordian title={product_catlog[i].name} data={product_catlog[i].product_items} onClickItem={(data) => this.itemClick(data)} />
                    </div>
                )
            }
            return elements;
        }

    }

    separateElement() {
        var separateElements = [];
        var multiElements = this.multipleElements();

        if (multiElements && multiElements.length > 0) {
            for (var i = 0; i < multiElements.length; i += 2) {
                var oneRow = [];
                oneRow.push(multiElements.slice(i, i + 2).map(item => {
                    return <div style={{ display: 'inline-block', justifyContent: "space-between" }}>{item}</div>
                }))
                separateElements.push(oneRow.map(itm => { return <div style={{ display: 'flex' }}>{itm}</div> }))
            }
            return separateElements;
        }
    }

    itemClick(data) {
        localStorage.setItem('productSelectedItem', JSON.stringify(data))
        this.props.history.push('product-view')
    }

    render() {
        return (
            <Fragment>
                <Layout
                    leftIcon={""}
                    headerContentLeft="Services"
                    headerContentRight=""
                    rightIcon=""
                    leftIconLink=""
                    itemClicked={(data) => this.props.history.push(data.path)}
                    contactusclick={() => this.props.history.push('/contactus')}
                >
                    <BodyContainer>
                        <div style={{ marginTop: "128px" }}>
                            <div className="pd-heading-div">
                                <Breadcrumb breadcrumbs={this.state.breadcrumbs} />
                            </div>

                            <Grid container style={{ flex: 1, flexDirection: 'row', marginTop: '39px' }}>
                                <Grid>
                                    {this.state.selectedProduct ? <Product item={this.state.selectedProduct} /> : null}
                                </Grid>
                                <Grid style={{ paddingLeft: '30px' }} >
                                    <Typography style={{ fontFamily: "Poppins", fontSize: '40px', lineHeight: '40px', fontWeight: 600, color: "#1F2937" }}> {this.state.selectedProduct.product_name}</Typography>
                                    <Grid container style={{ flex: 1, flexDirection: 'row', paddingTop: '32px' }}>
                                        <Grid>{this.separateElement()}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </BodyContainer>
                </Layout>
            </Fragment>
        );
    }
}

const selectedService = JSON.parse(localStorage.getItem('selectedService'))
const breadcrumbss = [
    <Link underline="hover" key="1" color="inherit" onClick={() => this.props.history.push('products')} >
        Products
    </Link>,
    <Typography key="3" color="text.primary">
        {selectedService ? selectedService.item : ""}
    </Typography>,
];

const data = [
    {
        "brokerName": "ASI",
        "brokerCode": "ASI"
    },
    {
        "brokerName": "ABC",
        "brokerCode": "ABC"
    },
]


const mapStateToProps = (state) => {
    return {
        dashboardData: state.DashboardReducer.dashboard,
        accountSummary: state.DashboardReducer.accountSummary,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardData: (callback) => dispatch(getDashboardData(callback)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);