const Images = {
    services: {
        img_enargy_service: require("../Assets/images/enargyMgt.svg").default,
        img_service_1: require("../Assets/images/Services/1.png").default,
        img_service_2: require("../Assets/images/Services/2.png").default,
        img_service_3: require("../Assets/images/Services/3.png").default,
        img_service_4: require("../Assets/images/Services/4.png").default,
        img_service_5: require("../Assets/images/Services/5.png").default,
        img_service_6: require("../Assets/images/Services/6.png").default,
    },
    home_partners: {
        img_apc: require("../Assets/SVG's/home partners/apc.svg").default,
        img_hager: require("../Assets/SVG's/home partners/hager.svg").default,
        img_schneider: require("../Assets/SVG's/home partners/schneider_electric.svg").default,
        img_siemens: require("../Assets/SVG's/home partners/siemens.svg").default,
    },
    dashboard: {
        img_dashboard: require("../Assets/images/Home/main_image.png").default
    },
    products: {
        img_product_view: require("../Assets/images/products/Product view/1.png").default,
        img_product_1: require("../Assets/images/products/1.png").default,
        img_product_2: require("../Assets/images/products/2.png").default,
        img_product_3: require("../Assets/images/products/3.png").default,
        img_product_4: require("../Assets/images/products/4.png").default,
    },
    news: {
        img_news_view: require("../Assets/images/News/News view/1.png").default,
        img_news_1: require("../Assets/images/News/1.png").default,
        img_news_2: require("../Assets/images/News/2.png").default,
        img_news_3: require("../Assets/images/News/3.png").default,
        img_news_4: require("../Assets/images/News/4.png").default,
        img_news_5: require("../Assets/images/News/5.png").default,
    },
    partners: {
        img_partner_1: require("../Assets/partners/1.png").default,
        img_partner_2: require("../Assets/partners/2.svg").default,
        img_partner_3: require("../Assets/partners/3.png").default,
        img_partner_4: require("../Assets/partners/4.png").default,
        img_partner_5: require("../Assets/partners/5.svg").default,
        img_partner_6: require("../Assets/partners/6.png").default,
        img_partner_7: require("../Assets/partners/7.png").default,
        img_partner_8: require("../Assets/partners/8.svg").default,
        img_partner_9: require("../Assets/partners/9.png").default,
        img_partner_10: require("../Assets/partners/10.png").default,
        img_partner_11: require("../Assets/partners/11.svg").default,
        img_partner_12: require("../Assets/partners/12.png").default,
        img_partner_13: require("../Assets/partners/13.png").default,
        img_partner_14: require("../Assets/partners/14.png").default,
        img_partner_15: require("../Assets/partners/15.png").default,
        img_partner_16: require("../Assets/partners/16.png").default,
        img_partner_17: require("../Assets/partners/17.png").default,
        img_partner_18: require("../Assets/partners/18.png").default,
        img_partner_19: require("../Assets/partners/19.png").default,

    },
    icon: {
        ic_arrow_down: require("../Assets/SVG's/socials/chevron-down (gray).svg").default,
        ic_f1: require("../Assets/SVG's/socials/Frame-1.svg").default,
        ic_f2: require("../Assets/SVG's/socials/Frame-2.svg").default,
        ic_f3: require("../Assets/SVG's/socials/Frame.svg").default,
    },
    logo: {
        logo_blue: require("../Assets/logo/logo-blue.svg").default,
        logo_white: require("../Assets/logo/logo-white.svg").default,
    }

};

export { Images }