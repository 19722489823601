import * as Types from './index';

export const getDashboardData = (callback) => {
    return {
        type: Types.GET_DASHBOARD_DATA,
        callback
    }
}

export const getAccountSummary = () => {
    return {
        type: Types.GET_ACCOUNT_SUMMARY
        
        
    }
}

export const getInvestmentAdvisor = (callback) => {
    return {
        type: Types.GET_INVESTMENT_ADVISOR,
        callback
    }
}

