import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../Layout/Layout";
import "./Careers.scss";
import BodyContainer from "../../../../Components/BodyArea/Body";
import CardComponent from "../../../../Components/Card/CardComponent";
import {
    getDashboardData,
} from "../../../../Actions/dashboardAction";
import {
    RadioField,
    FormField,
    SelectField,
    SubmitButton,
    Checkbox,
} from "../../../../Components/Form";
import { fixDecimalPoints } from "../../../../Utils/commonFunctions"
import { Grid, Container, Stack, Paper, Typography, Button } from "@mui/material"
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Images } from "../../../../Theme/Images";
import { addCareersService } from "../../../../Services/dashboardService";

const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username required").label("Username").max(50).matches(/^[a-zA-Z]+$/, "Invalid username"),
    name: Yup.string().required("Name required").label("Name").max(50).matches(/^[a-zA-Z_ ]*$/, "Invalid name"),
    email: Yup.string().required("Email required").max(50).email("Invalid email address").label("Email"),
    userType: Yup.string().required().label("User Type"),
});

const userTypes = [
    { label: "Internal", value: "Internal" },
    { label: "External", value: "External" },
];

function Careers(props) {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('')
    const [company, setCompany] = useState('')
    const [position, setPosition] = useState('Position')
    const [message, setMessage] = useState('')
    const [cv, setCv] = useState('')
    const [initialValues, setInitialValues] = useState({
        name: "",
        userName: "",
        email: "",
        status: "Active",
        permissionList: [],
        userType: "External",
    })

    const onLeftIconClick = () => {
    }

    const handleViewClick = () => {
        this.props.history.push('service-view')
        let obj = {
            item: "ABCDGSE"
        }
        localStorage.setItem("selectedService", JSON.stringify(obj))
    }

    const changeHandler = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setCv(reader.result)
        }
    }

    const onSubmit = async () => {
        let req = {
            name: name,
            contact_no: number,
            company: company,
            position: position,
            message: message,
            cv: cv
        }

        let response = await addCareersService(req)
        setName("")
        setNumber("")
        setCompany("")
        setMessage("")
    }





    return (
        <Fragment>
            <Layout
                leftIcon={""}
                headerContentLeft="Services"
                headerContentRight=""
                rightIcon=""
                leftIconLink=""
                itemClicked={(data) => props.history.push(data.path)}
                contactusclick={() => props.history.push('/contactus')}
            >
                <BodyContainer>
                    <div style={{ marginTop: "160px" }}>
                        <div className="cu-heading-div">
                            <h3 className="cu-header-text">Join Lorem Ipsum</h3>
                        </div>
                        <div className="cu-sub-div">
                            <h3 className="cu-sub-text">Molestie egestas aenean morbi faucibus ornare at nec. Volutpat a adipiscing neque, phasellus in in in dignissim sagittis.</h3>
                        </div>

                        <Grid>
                            <Formik
                                initialValues={initialValues}
                                //onSubmit={handleSubmit}
                                validationSchema={validationSchema}
                            >
                                {({ values, setFieldValue, handleChange, handleReset }) => {

                                    return (
                                        <Form>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container style={{ flex: 1, flexDirection: 'row' }}>
                                                        <Grid style={{ width: '558px' }}>
                                                            <input type="text" placeholder="Name" value={name} style={{ marginTop: '32px' }} onChange={(e) => setName(e.target.value)} />
                                                            <input type="text" placeholder="Contact No." value={number} style={{ marginTop: '24px' }} onChange={(e) => setNumber(e.target.value)} />
                                                            <input type="text" placeholder="Company" value={company} style={{ marginTop: '24px' }} onChange={(e) => setCompany(e.target.value)} />
                                                            <select className="classic" style={{ marginTop: '24px' }} value={position} onChange={(e) => setPosition(e.target.value)}>
                                                                <option value="Position">Position</option>
                                                            </select>
                                                            <Grid container style={{ flex: 1, flexDirection: 'row' }} style={{ marginTop: '24px' }}>
                                                                <h1 style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: "#1F2937", paddingTop: '8px' }}>Attach Your CV:</h1>
                                                                <label htmlFor="icon-button-file">
                                                                    <div style={{ width: '106px', height: '40px', borderStyle: 'solid', borderRadius: '4px', paddingTop: "8px", paddingBottom: '8px', borderColor: '#2563EB', borderWidth: '1px', display: 'flex', justifyContent: 'center', marginLeft: '32px' }}>
                                                                        <p style={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px', fontWeight: 400, color: '#2563EB' }}>Browse</p>
                                                                    </div>
                                                                </label>
                                                                <input id="icon-button-file"
                                                                    type="file" style={{ display: 'none' }} onChange={(e) => changeHandler(e)} />
                                                            </Grid>

                                                            <div style={{ marginTop: '24px' }}>
                                                                <div style={{ width: '139px', height: '40px', borderRadius: '4px', paddingTop: "8px", paddingBottom: '8px', display: 'flex', justifyContent: 'center', backgroundColor: '#3B82F6' }} onClick={() => onSubmit()}>
                                                                    <p style={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px', fontWeight: 600, color: '#EFF6FF' }}>Submit</p>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid style={{ width: '558px', height: '264px', marginLeft: '30px' }}>
                                                            <textarea type="text" placeholder="Message" value={message} style={{ marginTop: '32px' }} onChange={(e) => setMessage(e.target.value)} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                    </div>
                </BodyContainer>
            </Layout>
        </Fragment>
    );
}

const data = [
    {
        "brokerName": "ASI",
        "brokerCode": "ASI"
    },
    {
        "brokerName": "ABC",
        "brokerCode": "ABC"
    },
]


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Careers);